<template>
  <div>
    <div v-if="isCustom">
      <!-- {{value}} -->
      <el-input v-if="!autoCrc" v-model="code" @change="codeChange()" clearable :placeholder="placeholder"></el-input>
      <el-input v-else v-model="code" @change="codeChange()" clearable :placeholder="placeholder">
        <template slot="append">{{crcValue}}</template>
      </el-input>
    </div>
    <div v-else>
      <div v-if="brandData && brandData.isextend == 1 && brandData.extenddata" class="datas">
        <div class="item" v-for="(item,index) in datas" :key="index">
          <label class="title">{{titles[index]}}</label>
          <el-input v-if="item=='GD'" v-model="vars[index]" placeholder="固定" maxlength="2" @change="codeChange()"/>
          <el-input v-else disabled :value="getVarName(item)"></el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatCode } from '@/utils'
  import CRC from '@/utils/CRC'
  export default {
    props: {
      value: {
        type: String
      },
      autoCrc: {
        type: Boolean,
        default: false
      },
      isCustom: {
        type: Boolean,
        default: true
      },
      brandData: {
        type: Object
      },
      isFeedback: {
        type: Boolean,
        default: false
      },
      isHuatiao: {
        type: Boolean,
        default: false
      },
      placeholder:{
        type: String
      }
    },
    watch: {
      value: function(v) {
        this.valueChange(v)
      }
    },
    computed: {
      datas: function() {
        var result = []
        var list = this.isFeedback ? this.brandData.extenddata.fbdatas : this.isHuatiao?this.brandData.extenddata.htdatas: this.brandData.extenddata.datas
        if (list) {
          for (var item of list) {
            result.push(item)
          }
        }
        return result
      },
      titles: function() {
        var result = []
        var list = this.isFeedback ? this.brandData.extenddata.fbtitles : this.isHuatiao?this.brandData.extenddata.httitles: this.brandData.extenddata.titles
        if (list) {
          for (var item of (list || [])) {
            result.push(item)
          }
        }
        return result
      }
    },
    data () {
      return {
        code: '',
        crcValue: '',
        vars: []
      }
    },
    mounted() {
      this.valueChange(this.value)
    },
    methods: {
      valueChange(v) {
        this.vars = []
        this.code = ''
        this.crcValue = ''
        if (this.isCustom && v) {
          if (this.autoCrc) {
            this.code = v && v.substring(0, v.length - 6)
            this.crcValue = v && v.substring(v.length - 6, v.length)
          } else {
            this.code = v
          }
        } else {
          if (v) { this.vars = v.split(' ') }
        }
      },
      codeChange() {
        if (this.isCustom) {
          if (this.autoCrc) {
            this.code = formatCode(this.code)
            this.crcValue = this.code ? formatCode(CRC.ToModbusCRC16(this.code, true)) : ''
            this.$emit('input', formatCode(this.code + this.crcValue))
          } else {
            this.$emit('input', formatCode(this.code))
          }
        } else {
          var r = ''
          for (var i = 0; i < this.datas.length; i++) {
            if (i !== 0) r += ' '
            if (this.datas[i] === 'GD') {
              this.$set(this.vars, i, formatCode(this.vars[i] || ''))
              r += this.vars[i]
            } else {
              r += this.datas[i]
            }
          }
          this.$emit('input', r)
        }
      },
      getVarName(code) {
        if (code === 'XY') {
          return '效验'
        } else if(code&&code.startsWith('V')){
          return '变量' + (Number(code.substring(code.length - 1, code.length)) + 1)
        } else if(code == 'CR'){
          return 'CRC'
        } else if(code == 'HT'){
          return '滑条'
        }else {
          return code
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.datas{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-end;
  .el-input{
    width: 70px;
  }
  .item{
    margin-right: 5px;
  }
}
.title{
  display: block;
  text-align: center;
  font-size: 12px;
  color:#999;
  line-height: 18px;
}
</style>
