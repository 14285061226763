const plugins = [
  {
    name: '发送插件',
    code: 'send',
    subs: [
      {code: 'HEX', name: 'HEX字符', color:'#666'},
      {code: 'CRC', name: 'CRC校验', color:'#e6a23c'},
      {code: 'STR', name: '字符串', color:'#333'},

      {code: 'SID', name: '发送ID(1位)', color:'#409eff'},
      {code: 'SSN', name: '发送SN(12位)', color:'#409eff'},
      {code: 'SSV', name: '发送软件版本(字符串)', color:'#409eff'},
      {code: 'SHV', name: '发送硬件版本(1位)', color:'#409eff'},
      
    ]
  },
  {
    name: '发送反馈插件',
    code: 'sendback',
    subs: [
      {code: 'HEX', name: 'HEX字符', color:'#666'},
      {code: 'XX', name: '万能码', color:'#999'},
      {code: 'CRC', name: 'CRC校验', color:'#e6a23c'},
      {code: 'STR', name: '字符串', color:'#333'},

      {code: 'OR', name: '或', color:'#FF0000'},

      {code: 'GID', name: '获取ID(1位)', color:'#67c23a'},
      {code: 'GSN', name: '获取SN(12位)', color:'#67c23a'},
      {code: 'GSV', name: '获取软件版本(字符串)', color:'#67c23a'},
      {code: 'GHV', name: '获取硬件版本(1位)', color:'#67c23a'},
      
    ]
  },
  {
    name: '延时插件',
    code: 'delay',
  },
  {
    name: '升级插件',
    code: 'update',
  },
  // {
  //   name: '发送反馈软件版本插件',
  //   code: 'sendback_sv',
  // },
]
export default plugins