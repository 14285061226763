<template>
  <div>
    <el-tag type="info" :style="{color:'#FFF'}" :color="value.color" closable @close="$emit('delete',value)">{{ value.name }}</el-tag>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    data () {
      return {
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>