<template>
    <div>
      <el-popover  placement="top-start" trigger="click" width="500" title="万能码组件占位">
        <div>
          <el-input type="number" v-model="value.value" placeholder="请输入多少位"></el-input> 
        </div>
        <el-tag slot="reference" type="info" :style="{color:'#FFF'}" :color="value.color" closable @close="$emit('delete',value)">
            {{ value.name }}({{ value.value||1 }}位)</el-tag>
      </el-popover>
    </div>
  </template>
  <script>
    export default {
      props: ['value'],
      data () {
        return {
        }
      }
    }
  </script>
  <style lang="scss" scoped>
  .el-tag{
    cursor: pointer;
  }
  </style>