<template>
  <div>
    <el-popover ref="pop"  placement="top-start" trigger="click" width="500" title="CRC校验组件-选择要校验项">
      <div class="data">
        <template v-for="(item,index) in data"  >
          <div class="data-item" v-if="item.code!='CRC'">
            <el-tag type="info" :style="{color:'#FFF'}" :color="item.color">{{ (item.code=='HEX'||item.code=='STR')&&item.value || item.name }}</el-tag>
            <!-- <el-checkbox v-model="checks[index]" @change="checkboxChange(item)"></el-checkbox> -->
            <el-checkbox :value="getIsCheck(item.id)" @change="checkboxChange($event, item)"></el-checkbox>
          </div>
        </template>
      </div>
      <el-tag slot="reference" type="info" :style="{color:'#FFF'}" :color="value.color" closable @close="$emit('delete',value)">{{ value.name }}</el-tag>
    </el-popover>
  </div>
</template>
<script>
  import _ from 'lodash'
  export default {
    props: ['value', 'data'],
    methods:{
      checkboxChange(event, item){
        let list = this.value.value||[]
        if(event){
          list.push(item.id)
        }else{
          list.splice(list.indexOf(item.id),1)
        }
        
      },
      getIsCheck(id){
        if(this.value && this.value.value){
          let findItem = this.value.value.find(a => a==id)
          if(findItem)return true
        }
        return false
      }
    },
    data () {
      return {
        checks: []
      }
    },
    created(){
      if(!this.value.value){
        this.$set(this.value,'value', [])
      }
    }
  }
</script>
<style lang="scss" scoped>
.el-tag{
  cursor: pointer;
}
.data{
  display: flex;
  flex-wrap: wrap;
  .data-item{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 2px;
  }
}
</style>