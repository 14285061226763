<template>
  <div>
    发送：
    <plugin_send v-model="value.send"></plugin_send>
    反馈：
    <plugin_send v-model="value.back" code="sendback"></plugin_send>
    反馈超时时间：
    <el-input type="number" v-model="value.timeout" style="width: 120px;"></el-input>ms
  </div>
</template>
<script>
  import plugin_send from './plugin-send.vue'  
  export default {
    props: ['value'],
    components: {plugin_send},
    data () {
      return {
      }
    },
    watch:{
    },
    methods:{
    },
    created(){
      if(!this.value){
        this.$emit('input',{timeout:4000})
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>