<template>
  <div>
    <el-input type="number" v-model="value.delay" style="width: 120px;"></el-input>ms
  </div>
</template>
<script>
  export default {
    props: ['value'],
    components: {},
    data () {
      return {
      }
    },
    watch:{
    },
    methods:{
    },
    created(){
      if(!this.value){
        this.$emit('input',{delay:4000})
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>