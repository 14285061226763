<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible" class="rule">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="规则名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="规则名称"></el-input>
    </el-form-item>
    </el-form>
    <el-row :gutter="10" v-for="(item,index) in details" :key="index" type="flex" :align="'middle'" class="plugin">
      <el-col :span="20">
        <rule-plugin v-model="details[index]"></rule-plugin>
      </el-col>
      <el-col :span="4">
        <el-button type="text" @click="move(index,-1)">上移</el-button>
        <el-button type="text" @click="move(index,1)">下移</el-button>
        <el-button type="text" @click="remove(index)">删除</el-button>
      </el-col>
    </el-row>

    <el-button style="margin-top: 10px;" size="small" type="primary" @click="addDetail">添加明细</el-button>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import inputCode from '@/components/input-code16'
  import rulePlugin from '@/components/plugins'
  import _ from 'lodash'

  const initDetail = {
            code: 'send',
            data: []
          }

  export default {
    components: {
      inputCode,
      rulePlugin
    },
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          packRule: ''
        },
        dataRule: {
          name: [
            { required: true, message: '规则名称不能为空', trigger: 'blur' }
          ]
        },
        details: [_.cloneDeep(initDetail)]
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.details = [_.cloneDeep(initDetail)]
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsrule/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.wxsRule.name
                this.details = JSON.parse(data.wxsRule.detail||'[]')
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsrule/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'detail': JSON.stringify(this.details)
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      addDetail(){
        this.details.push(_.cloneDeep(initDetail))
      },
      move(index, o){
        if(o>0){//下移
          if(index < this.details.length){
            this.details[index] = this.details.splice(index+1, 1, this.details[index])[0];
          }
        }else{//上移
          if(index > 0){
            this.details[index] = this.details.splice(index-1, 1, this.details[index])[0];
          }
        }
      },
      remove(index){
        this.details.splice(index, 1)
      }
    }
  }
</script>
<style lang="scss" scoped>
.rule{
  :deep(.el-dialog){
    width: 1200px;
  }
}
.plugin{
  background-color: #f2f6fc;
  padding: 4px;
  margin-bottom: 4px;
}
</style>
