<template>
  <div>
    <el-select v-model="value.value" style="width: 300px">
      <el-option label="50拆包规则7+50+2" value="1"></el-option>
      <el-option label="32拆包规则8+32+2" value="2"></el-option>
    </el-select>
  </div>
</template>
<script>
  export default {
    props: ['value'],
    components: {},
    data () {
      return {
      }
    },
    watch:{
    },
    methods:{
    },
    created(){
      if(!this.value){
        this.$emit('input',{value:'1'})
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>