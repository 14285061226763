<template>
  <div>
    <div class="subs">
      <component v-for="(item,index) in value" :key="item.id" :is="getSubComponentName(item.code)" class="sub" v-model="value[index]" :data="value" @delete="subDelete"></component>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="showAddSub=true"></el-button>
    </div>
    <el-dialog width="300px" title="选择要添加的组件" :visible.sync="showAddSub" append-to-body>
      <div class="subs">
        <el-tag v-for="item in subs" :key="item.code" type="info" :style="{color:'#FFF'}" :color="item.color" @click="addSubClick(item)">{{ item.name }}组件</el-tag>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import plugins from './data'
  import _ from 'lodash'

  //批量导入
  const allComponents = require.context("./sub-plugins", false, /\.vue$/);
  let resComponents = {};
  allComponents.keys().forEach(comName => {
    let name = comName;
    const comp = allComponents(comName);
    resComponents[name.replace(/^\.\/(.*)\.\w+$/, "$1")] = comp.default;
  });


  export default {
    props: ['value','code'],
    components: resComponents,
    data () {
      return {
        showAddSub: false,
        subs: plugins.find(a => a.code==(this.code||'send')).subs
      }
    },
    methods:{
      addSubClick(item){
        let newItem = _.cloneDeep(item)
        newItem.id = _.uniqueId(_.now())
        this.value.push(newItem)
        this.showAddSub = false
      },
      getSubComponentName(code){
        if(code == 'HEX' || code == 'CRC' || code == 'STR' || code=='XX'){
          return code
        }else{
          return 'AAA'
        }
      },
      subDelete(item){
        this.value.splice(this.value.indexOf(item), 1)
      }
    },
    created(){
      if(!this.value){
        this.$emit('input',[])
      }
    }
  }
</script>
<style lang="scss" scoped>
.subs{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 36px;
  .el-tag{
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .sub{
    margin: 4px 4px 4px 0;
  }
}
</style>